import ringingAudioFilePath from "@/assets/ringing.mp3";
import { sendCallResult } from "@/features/callResultRegister/sendCallResult";
import { CallPad } from "@/features/CallScreen/CallScreenCallPad";
import {
  CallScreenResultRegister,
  Result,
} from "@/features/CallScreen/CallScreenResultRegister";
import { useTranscripts } from "@/hooks/useTranscript";
import { CallHistoryTranscript, CallResult } from "@/models/CallHistory";
import { request } from "@/models/telai-backend/client";
import { paths } from "@/models/telai-backend/schema";
import { setCurrentCall, setTwilioCall } from "@/store/callSlice";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { AppDispatch, RootState } from "@/store/store";
import { setMissedManualInboundCallOn } from "@/store/userSlice";
import isValidURL from "@/utils/isValidURL";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

let intervalId: NodeJS.Timeout | null = null;
let sentManualInboundCallResult = false;

const CallScreen = () => {
  const [callResultRegister, setCallResultRegister] = useState(false);
  const currentCallId = useSelector(
    (state: RootState) => state.call?.currentCallId,
  );
  const currentCall = useSelector(
    (state: RootState) => state.call?.calls[currentCallId],
  );
  const [callIsAccepted, setCallIsAccepted] = useState(false);
  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const companyId = useSelector(
    (state: RootState) => state.user.loggedInUser.tenantId,
  );

  const user = useSelector((state: RootState) => state.user.loggedInUser);
  const twilioCall = useSelector((state: RootState) => state.call.twilioCall);
  const [contactListItem, setContactListItem] = useState<
    | paths["/contacts/{contactId}"]["get"]["responses"]["200"]["content"]["application/json"]
    | null
  >(null);
  const [contactList, setContactList] = useState<
    | paths["/contact_lists/{listId}"]["get"]["responses"]["200"]["content"]["application/json"]
    | null
  >(null);
  const [callResultRegistered, setCallResultRegistered] = useState(false);
  const [previousCallInfo, setPreviousCallInfo] = useState<
    | paths["/calls/{callId}"]["get"]["responses"]["200"]["content"]["application/json"]
    | null
  >(null);

  const callResultLabels = useSelector(
    (state: RootState) => state.company.config.customCallResultLabels,
  );

  const transcript = useTranscripts(currentCallId, companyId);

  const transcriptArray: CallHistoryTranscript[] = useMemo(() => {
    if (!transcript[currentCallId]?.transcript) return [];
    return transcript[currentCallId].transcript;
  }, [transcript, currentCallId]);

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const { tenantId } = useParams();

  // 前回コール情報の取得
  useEffect(() => {
    console.log("callentCall====>>", currentCall, contactListItem, contactList);
    if (!currentCall) {
      return;
    }

    const contactId =
      (currentCall.direction === "INBOUND" && currentCall.fromContactId) ||
      (currentCall.direction === "OUTBOUND" && currentCall.toContactId) ||
      null;

    const fetch = async () => {
      console.log("fetch====>>", contactId);
      if(contactId){
        const contactRes = await request({
          path: "/calls",
          httpMethod: "get",
          params: {
            query: {
              contactId,
              limit: 2,
              offset: 0,
            },
          },
        });
        if (contactRes.result === "error") {
          console.error(contactRes.error);
          return;
        }

        let previousCallId: string | undefined = undefined;
        contactRes.data.calls.forEach((call) => {
          if (call.id === currentCall.id) return;
          previousCallId = call.id;
        });

        if (!previousCallId) return;

        const callRes = await request({
          path: "/calls/{callId}",
          httpMethod: "get",
          params: {
            paths: { callId: previousCallId },
          },
        });
        if (callRes.result === "error") {
          console.error(callRes.error);
          return;
        }
        setPreviousCallInfo(callRes.data);
     }else {
        //電話番号からコンタクトを取得
        const contact = await request({
          path: "/calls",
          httpMethod: "get",
          params: {
            query: { 
              limit: 2,
              offset: 0,
              phoneNumber: currentCall.to,
             },
          },
        });
        console.log("contact====>>", contact);
        if (contact.result === "error"|| contact.data.calls.length === 0) {
          console.log("contact not found");
          return;
        }
        setPreviousCallInfo(contact.data.calls[1]);
     }
    };

    fetch();
  }, [currentCall]);

  // メモにURLが含まれている場合にはURLを入力
  const contactListMetadataURL: string | undefined = useMemo(() => {
    if (contactListItem && contactList) {
      const urlMetadataKey = contactList.metadataKeys.find((key) => {
        const value = contactListItem.metadata[key];
        return isValidURL(value);
      });
      if (urlMetadataKey) {
        return contactListItem.metadata[urlMetadataKey];
      }
    }
    return undefined;
  }, [contactListItem, contactList]);

  const displayURL = useMemo(() => {
    if (!searchQuery) return null;
    return contactListMetadataURL || encodeURI(`https://www.google.com/search?q=${searchQuery}&igu=1`);
  }, [searchQuery, contactListMetadataURL]);

  const Iframe = useMemo(() => {
    console.log({ searchQuery });

    if (searchQuery === null) {
      return () => (
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ opacity: 0.4 }}>(コール先情報なし)</Typography>
        </Stack>
      );
    }

    return () => (
      <Box width="100%" height="100%" display="flex" flexDirection="column">
        <Box
          sx={{ bgcolor: "#e5e5e5", p: 2 }}
          display="flex"
          alignItems="center"
          flexDirection="column"
          borderBottom="1px solid #e0e0e0"
        >
          <Button
            variant="contained"
            color="primary"
            href={displayURL}
            target="_blank"
            rel="noreferrer noopener"
            sx={{ m: 2, textTransform: "none" }}
          >
            {contactListMetadataURL || `Google検索: ${searchQuery}`}
          </Button>
          <Typography textAlign="center">
            (reCAPTCHAが表示される場合はボタンを押すと検索できます)
          </Typography>
        </Box>
        <iframe
          width="100%"
          height="100%"
          src={displayURL}
        ></iframe>
      </Box>
    );
  }, [searchQuery]);

  const handleEndCall = () => {
    setCallResultRegister(true);
  };

  // currentCallが変更されたらコール先情報を取得してcontactListItem, contactListをセット
  useEffect(() => {
    if (!currentCall) return;

    if (
      currentCall.toContactId === null &&
      currentCall.fromContactId === null
    ) {
      setContactListItem(null);
      setContactList(null);
      return;
    }

    const fetch = async () => {
      const contactRes = await request({
        path: "/contacts/{contactId}",
        httpMethod: "get",
        params: {
          paths: {
            contactId:
              currentCall.direction === "INBOUND"
                ? currentCall.fromContactId
                : currentCall.toContactId,
          },
        },
      });
      if (contactRes.result === "error") {
        console.error(contactRes.error);
        return;
      }
      setContactListItem(contactRes.data);

      const contactListRes = await request({
        path: "/contact_lists/{listId}",
        httpMethod: "get",
        params: {
          paths: { listId: contactRes.data.contactListId },
        },
      });
      if (contactRes.result === "error") {
        console.error(contactRes.error);
        return;
      }
      setContactList(contactListRes.data);
    };

    fetch();
  }, [currentCall]);

  // 検索クエリの生成
  useEffect(() => {
    if (contactListItem && contactList) {
      setSearchQuery(
        contactListItem.name.replace(/\s+/g, "+") +
          "+" +
          contactList.description,
      );
    } else {
      setSearchQuery(null);
    }
  }, [contactListItem, contactList]);

  // コール結果の登録
  const handleResultSubmit = useCallback(
    async (submit: Result) => {
      if (!submit.callResult) throw new Error("empty call result");

      try {
        await sendCallResult({
          callId: currentCall.id,
          callResult: submit.callResult as CallResult,
          nextCallDate: submit.nextCallDate,
          callMemo: submit.callMemo,
        });
        setCallResultRegistered(true);
      } catch (e) {
        console.error(e);
        dispatch(
          setSnackbar({
            text: "コール結果の登録に失敗しました。ホームへ戻ります。",
            severity: "error",
            open: true,
          }),
        );
      } finally {
        dispatch(setTwilioCall(null));
        dispatch(setCurrentCall({ callId: null }));
        navigate(`/${tenantId}/`);
      }
    },
    [dispatch, user.id, currentCall, contactListItem, twilioCall],
  );

  // コール結果登録後にユーザーの状態がIN_CALL_WRAP_UPでなくなったらホーム画面へ遷移
  // (IN_CALL_WRAP_UPの状態でホーム画面へ遷移すると/callscreenへ戻されてしまうため)
  useEffect(() => {
    if (!callResultRegistered) return;
    if (["IN_CALL", "IN_CALL_WRAP_UP"].includes(user.status)) return;
    dispatch(
      setSnackbar({
        text: "正常にコール結果を登録しました。ホームへ戻ります。",
        severity: "success",
        open: true,
      }),
    );
    navigate(`/${tenantId}/`);
    setCallResultRegistered(false);
    dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: false }));
  }, [callResultRegistered, user.status]);

  const fetchCurrentCall = useCallback(() => {
    if (currentCall) {
      console.log({
        message: "currentCall が取得できました",
        currentCall,
      });
      clearInterval(intervalId);
      return;
    }

    console.log({
      message: "currentCall が取得できませんでした",
      currentCall,
    });

    let callSid: string | null | undefined = currentCallId;
    if (!callSid) {
      user.assignedCallIds.forEach((callId) => {
        callSid = callId;
        return;
      });
    }
    if (!callSid) {
      user.unfilledNoteResultCallIds.forEach((callId) => {
        callSid = callId;
        return;
      });
    }
    if (!callSid) {
      callSid = twilioCall.customParameters.get("callId");
    }

    dispatch(setCurrentCall({ callId: callSid }));
  }, [currentCall, twilioCall, dispatch]);

  useEffect(() => {
    if (!currentCall && twilioCall) {
      console.log({
        message: "currentCall が null か undefined です",
        twilioCall,
        currentCall,
      });

      intervalId = setInterval(fetchCurrentCall, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentCall, twilioCall, fetchCurrentCall]);

  // コール先情報のフェッチ, コールバックの登録
  useEffect(() => {
    if (!twilioCall) return;

    console.log(twilioCall?.customParameters);
    console.log(twilioCall?.outboundConnectionId);
    console.log("incoming callSid:" + twilioCall?.parameters?.CallSid);

    twilioCall.on("connection", () => {
      console.log("call connected");
    });

    twilioCall.on("cancel", () => {
      console.error("call canceled");
      // callInstance.disconnect()
      setCallResultRegister(true);
    });

    twilioCall.on("error", () => {
      console.error("call error");
      // callInstance.disconnect()
      setCallResultRegister(true);
    });

    twilioCall.on("disconnected", () => {
      console.log("call disconnected");
      setCallResultRegister(true);
    });
  }, [twilioCall]);

  // 通話が COMPLETED になったら自動で結果登録画面へ遷移
  useEffect(() => {
    const status = currentCall?.status;

    if (status === "COMPLETED") {
      twilioCall?.disconnect();
      setCallResultRegister(true);
      if (audioElementRef.current) audioElementRef.current.pause();
    }

    console.log(
      currentCall?.direction,
      status,
      callIsAccepted,
      sentManualInboundCallResult,
    );

    // 手動受電時に通話に出れなかった場合、
    // 結果登録画面をスキップしてホーム画面へ遷移し通知を表示
    if (
      currentCall?.direction === "INBOUND" &&
      status === "COMPLETED" &&
      !callIsAccepted &&
      !sentManualInboundCallResult
    ) {
      sentManualInboundCallResult = true;
      dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: true }));
      sendCallResult({
        callId: currentCall?.id,
        callResult: "MANUAL_INBOUND_UNREACHABLE",
      }).then(() => {
        dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: false }));
        dispatch(setMissedManualInboundCallOn(true));
        sentManualInboundCallResult = false;
      });
    } else {
      dispatch(setMissedManualInboundCallOn(false));
    }
  }, [
    callIsAccepted,
    currentCall?.direction,
    currentCall?.id,
    currentCall?.status,
    dispatch,
    twilioCall,
  ]);

  /**
   * URLかどうかを判定
   * @param {string} string - 判定対象の文字列
   * @returns {boolean} URLかどうか
   */
  const isUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  // トスアップ通知用音声の再生
  useEffect(() => {
    const ring = async () => {
      if (!twilioCall || twilioCall.status() === "closed") return;
      if (audioElementRef.current && !audioElementRef.current.paused) return;

      try {
        const audio = new Audio(ringingAudioFilePath);
        audio.loop = true;
        audio.play();
        audioElementRef.current = audio;
      } catch (e) {
        console.error(e);
        return null;
      }
    };
    ring();
  }, []);

  const handleOnUnmute = useCallback(() => {
    setCallIsAccepted(true);
    if (audioElementRef.current) audioElementRef.current.pause();
  }, [audioElementRef.current]);

  return (
    <Box display="flex" height="100vh" width="100%" overflow="hidden">
      <Box width={380}>
        <Stack height="100%">
          <>
            {callResultRegister ? (
              <CallScreenResultRegister
                callDuration="00:00"
                onSubmit={handleResultSubmit}
                manualCalling={currentCall?.batchId === null}
                direction={
                  currentCall?.direction === "INBOUND" ? "INCOMING" : "OUTGOING"
                }
              ></CallScreenResultRegister>
            ) : (
              <CallPad
                call={currentCall}
                onEndCall={handleEndCall}
                onUnmute={handleOnUnmute}
                displayName={contactListItem?.name}
              ></CallPad>
            )}
            <Paper
              sx={{
                bgcolor: "#454545",
                color: "#fff",
                borderRadius: 0,
                p: 4,
                height: 660,
                maxHeight: 660,
                overflowY: "scroll",
                scrollbarColor: "#666666 #454545",
              }}
            >
              <Stack>
                <Typography sx={{ pb: 1, px: 1 }} textAlign="left">
                  基本情報
                </Typography>
                <Table
                  size="small"
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: "none",
                      color: "#fff",
                      fontSize: "0.8rem",
                      padding: "2px 12px",
                    },
                  }}
                >
                  <TableBody>
                    {(!contactListItem ||
                      !Object.keys(contactListItem).length) && (
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          sx={{ textAlign: "center", height: "auto", px: 0 }}
                        >
                          <Typography
                            sx={{
                              opacity: 0.5,
                              fontSize: "0.85rem",
                              bgcolor: "rgba(255,255,255,0.1)",
                              p: 4,
                            }}
                          >
                            コールリスト情報なし
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {contactListItem && (
                      <TableRow>
                        <TableCell>会社名</TableCell>
                        <TableCell>
                          {isUrl(contactListItem?.name) ? (
                            <a
                              style={{
                                color: "white",
                                textDecoration: "none",
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.color = "white")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.color = "white")
                              }
                              onFocus={(e) =>
                                (e.currentTarget.style.color = "white")
                              }
                              onBlur={(e) =>
                                (e.currentTarget.style.color = "white")
                              }
                              href={contactListItem.name}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {contactListItem.name}
                            </a>
                          ) : (
                            contactListItem?.name || "─"
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {contactList &&
                      contactList.metadataKeys.slice(0, 7).map((label) => (
                        <TableRow>
                          <TableCell>{label}</TableCell>
                          <TableCell
                            sx={{
                              textAlign: !contactListItem?.metadata?.[label]
                                ? "center"
                                : "left",
                            }}
                          >
                            {isUrl(contactListItem?.metadata?.[label]) ? (
                              <a
                                style={{
                                  color: "white",
                                  textDecoration: "none",
                                }}
                                onMouseOver={(e) =>
                                  (e.currentTarget.style.color = "white")
                                }
                                onMouseOut={(e) =>
                                  (e.currentTarget.style.color = "white")
                                }
                                onFocus={(e) =>
                                  (e.currentTarget.style.color = "white")
                                }
                                onBlur={(e) =>
                                  (e.currentTarget.style.color = "white")
                                }
                                href={contactListItem.metadata[label]}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {contactListItem.metadata[label]}
                              </a>
                            ) : (
                              contactListItem?.metadata?.[label] || "─"
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Stack>

              <Divider
                sx={{ borderColor: "#fff", opacity: "0.2", my: 4 }}
              ></Divider>

              <Stack gap={1.5} px={1}>
                <Typography textAlign="left">会話ログ</Typography>
                {transcriptArray && transcriptArray.length ? (
                  transcriptArray.map(({ speaker, content }, index) => (
                    <Stack
                      pl={speaker === "TO" ? 0 : 4}
                      pr={speaker === "TO" ? 4 : 0}
                      key={index}
                    >
                      <Stack
                        key={index}
                        direction="row"
                        display="inline-flex"
                        gap={1}
                        bgcolor="rgba(255,255,255,0.1)"
                        ml={speaker === "TO" ? 0 : "auto"}
                        mr={speaker === "TO" ? "auto" : 0}
                        py={0.5}
                        px={1}
                      >
                        <Typography
                          fontSize="0.7rem"
                          sx={{ opacity: 0.6 }}
                          textAlign={speaker === "TO" ? "left" : "right"}
                          order={speaker === "TO" ? 0 : 1}
                          whiteSpace="nowrap"
                        >
                          {speaker === "TO" ? "顧客" : "AI"}
                        </Typography>
                        <Typography
                          fontSize="0.8rem"
                          textAlign="left"
                          lineHeight="1.2"
                        >
                          {content}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))
                ) : (
                  <Box bgcolor="rgba(255,255,255,0.05)" p={4}>
                    <Typography fontSize="0.85rem" sx={{ opacity: "0.5" }}>
                      会話ログなし
                    </Typography>
                  </Box>
                )}
              </Stack>

              <Divider
                sx={{ borderColor: "#fff", opacity: "0.2", my: 4 }}
              ></Divider>

              <Stack>
                <Typography sx={{ pb: 1, px: 1 }} textAlign="left">
                  前回コール
                </Typography>
                <Table
                  size="small"
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: "none",
                      color: "#fff",
                      fontSize: "0.8rem",
                      padding: "2px 12px",
                    },
                  }}
                >
                  <TableBody>
                    {previousCallInfo ? (
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          sx={{
                            textAlign: "left",
                            height: "auto",
                            width: "auto",
                            px: 0,
                          }}
                        >
                          <Typography sx={{ fontSize: "0.85rem", p: 0 }}>
                            {/* 通話ID: {previousCallInfo.id}<br /> */}
                            通話日時:{" "}
                            {previousCallInfo.createdAt
                              ? dayjs(previousCallInfo.createdAt).format(
                                  "YYYY/MM/DD HH:mm",
                                )
                              : ""}
                            <br />
                            コール結果:{" "}
                            {callResultLabels[previousCallInfo.noteResult] ||
                              callResultLabels.noteResult}
                            <br />
                            コールメモ: {previousCallInfo.noteContent || "なし"}
                            <br />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          sx={{ textAlign: "center", height: "auto", px: 0 }}
                        >
                          <Typography
                            sx={{
                              opacity: 0.5,
                              fontSize: "0.85rem",
                              bgcolor: "rgba(255,255,255,0.1)",
                              p: 4,
                            }}
                          >
                            前回コール情報なし
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Stack>
            </Paper>
          </>
        </Stack>
      </Box>
      <Box flexGrow={1}>
        <Iframe />
      </Box>
    </Box>
  );
};

export default CallScreen;
