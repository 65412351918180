import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CallHistoryRecord,
  CallHistoryTranscript,
  CallResult,
  callResults,
} from "@/models/CallHistory";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import {
  ContentCopy,
  Pause,
  PhoneCallback,
  PhoneForwarded,
  PlayArrow,
  QuestionMark,
  Download,
} from "@mui/icons-material";
import { useCallListMetadata } from "@/hooks/useCallList";
import { useScripts } from "@/hooks/useScripts";
import { useTranscripts } from "@/hooks/useTranscript";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { getScriptLines } from "@/store/callSlice";
import { useDispatch } from "react-redux";
import { CallHistoryCursor } from "@/features/CallHistory/CallHistoryCursor";
import dayjs from "dayjs";
import { request } from "@/models/telai-backend/client";
import { useNavigate } from "react-router-dom";

function formatDuration(value: number) {
  const minute = Math.floor(value / 60);
  const secondLeft = (value - minute * 60).toFixed(0);
  return `${minute}:${value - minute * 60 < 10 ? `0${secondLeft}` : secondLeft}`;
}

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const HistoryDetail = () => {
  const callResultLabels = useSelector(
    (state: RootState) => state.company.config.customCallResultLabels,
  );
  const location = useLocation();
  const urlParameteres = useParams();
  const { tenantId } = useParams();
  const callHistoryId = urlParameteres.id;
  const dispatch = useDispatch<AppDispatch>();
  const [callHistory, setCallHistory] = useState<CallHistoryRecord | null>(
    null,
  );
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const [callMemo, setCallMemo] = useState("");
  const callListMetadata = useCallListMetadata();
  const scripts = useScripts();
  const scriptLines = useSelector(
    (state: RootState) =>
      callHistory && state.call.scriptLines[callHistory.scriptId],
  );
  const companyId = useSelector(
    (state: RootState) => state.user.loggedInUser.tenantId,
  );
  const transcript = useTranscripts(callHistoryId, companyId);

  const transcriptArray: CallHistoryTranscript[] = useMemo(() => {
    if (!transcript[callHistoryId]?.transcript) return [];
    return transcript[callHistoryId].transcript;
  }, [transcript, callHistoryId]);

      const navigate = useNavigate();

  const manualCallNavigateUrl = (phoneNumber: string, ids:string) =>{
    return `/${tenantId}/manualcall?p=${phoneNumber.replace(/^\+81/, "0")}${ids ? `&ids=${ids}` : ""}`;
  }

  const breadcrumbRefs: BreadcrumbRef[] = useMemo(
    () => [
      {
        title: "コール履歴",
        navigateTo: `/${tenantId}/history`,
      },
      {
        title: "詳細",
      },
    ],
    [],
  );

  const onChangeCallResult = useCallback(
    async ({
      result,
      callMemo,
    }: {
      result?: CallResult;
      callMemo?: string;
    }) => {
      dispatch(
        setLoadingBackdrop({ key: "CallHistoryNoteResultUpdate", state: true }),
      );
      try {
        await request({
          path: "/calls/{callId}/note",
          httpMethod: "put",
          params: {
            paths: {
              callId: callHistoryId,
            },
            body: {
              content: callMemo,
              result,
            },
          },
        });
        setCallHistory({
          ...callHistory,
          callMemo: callMemo || callHistory.callMemo,
          result: result || callHistory.result,
        });
        dispatch(
          setSnackbar({
            open: true,
            text: "正常に更新しました。",
            severity: "success",
          }),
        );
      } catch (error) {
        console.error(error);
        dispatch(
          setSnackbar({
            open: true,
            text: "更新に失敗しました。",
            severity: "error",
          }),
        );
      } finally {
        dispatch(
          setLoadingBackdrop({
            key: "CallHistoryNoteResultUpdate",
            state: false,
          }),
        );
      }
    },
    [callHistoryId, dispatch, callHistory],
  );

  const headerItems = useMemo(
    () => [
      {
        label: "コール先電話番号",
        content: (
          <Box sx={{ whiteSpace: "nowrap", cursor: 'pointer' }} onClick={() => {navigate(manualCallNavigateUrl(callHistory?.phoneNumber, callHistory?.callListId)); }}>
            {callHistory?.callDirection === "INCOMING" ? (
              <PhoneCallback
                htmlColor="#7FB50B"
                sx={{ mr: 0.5, fontSize: "1.15rem", mb: -0.5 }}
                fontSize="small"
              />
            ) : callHistory?.callDirection === "OUTGOING" ? (
              <PhoneForwarded
                htmlColor="#E15E14"
                sx={{ mr: 0.5, fontSize: "1.15rem", mb: -0.5 }}
                fontSize="small"
              />
            ) : (
              <QuestionMark
                sx={{ mr: 0.5, fontSize: "0.95rem" }}
                color="inherit"
              ></QuestionMark>
            )}
            <Typography display="inline" noWrap >
              {callHistory?.phoneNumber || "─"} {/* ここ変更しました */}
            </Typography>
          </Box>
        ),
      },
      {
        label: "会社名",
        content: callHistory?.companyName || "─",
      },
      {
        label: "コール日時",
        content: callHistory?.calledAt ? (
          <Stack>
            <Box>{dayjs(callHistory?.calledAt).format("YYYY-MM-DD")}</Box>
            <Box>{dayjs(callHistory?.calledAt).format("HH:mm:ss")}</Box>
          </Stack>
        ) : (
          "─"
        ),
        dense: true,
      },
      {
        label: "コール結果",
        content: (
          <>
            <FormControl size="small">
              <Select
                value={callHistory?.result || "TOSSUP_NOT_REGISTERED"}
                variant="standard"
                onChange={(e) => {
                  onChangeCallResult({ result: e.target.value as CallResult });
                }}
              >
                {callResults.map((result) => (
                  <MenuItem key={result} value={result}>
                    {callResultLabels[result]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ),
        dense: true,
      },
      {
        label: "コールリスト",
        content:
          (callListMetadata &&
            callListMetadata[callHistory?.callListId]?.name) ||
          "─",
      },
      {
        label: "スクリプト",
        content: (scripts && scripts[callHistory?.scriptId]?.name) || "─",
      },
    ],
    [callHistory, callListMetadata, scripts, callResultLabels],
  );

  // 通話履歴のフェッチ
  useEffect(() => {
    if (!callHistoryId) return;
    const fetch = async () => {
      const callHistoryFilter = {
        callHistoryId: callHistoryId,
      };
      const cursor = new CallHistoryCursor({ filter: callHistoryFilter });
      const history = await cursor.getCallHistory(
        callHistoryFilter.callHistoryId,
      );
      console.log("これがhistory", history);
      console.log("これがcallHistoryId", history[callHistoryId]);
      setCallHistory(history.callHistoryData);
      setCallMemo(history.callHistoryData.callMemo);
    };
    fetch();
  }, [callHistoryId]);

  // スクリプトのフェッチ
  useEffect(() => {
    if (!callHistory || !callHistory.scriptId) return;
    const fetch = async () => {
      dispatch(
        setLoadingBackdrop({
          key: "CallHistoryDetailFetchScript",
          state: true,
        }),
      );
      await dispatch(getScriptLines({ scriptId: callHistory.scriptId }));
      dispatch(
        setLoadingBackdrop({
          key: "CallHistoryDetailFetchScript",
          state: false,
        }),
      );
    };
    fetch();
  }, [callHistory]);

  // 通話音声のフェッチ
  useEffect(() => {
    if (!callHistoryId || audioRef.current) return;

    console.log("fetching audio", callHistoryId);
    const fetch = async () => {
      try {
        dispatch(
          setLoadingBackdrop({
            key: "CallHistoryDetailFetchAudio",
            state: true,
          }),
        );
        const blob = await request({
          path: "/calls/{callId}/recording",
          httpMethod: "get",
          responseType: "blob",
          params: {
            paths: {
              callId: callHistoryId,
            },
          },
        });
        const blobUrl = URL.createObjectURL(blob.data);
        const newAudio = new Audio(blobUrl);
        newAudio.addEventListener("loadedmetadata", () =>
          setAudioDuration(newAudio.duration),
        );
        newAudio.addEventListener("timeupdate", () => {
          setAudioCurrentTime(newAudio.currentTime);
        });
        audioRef.current = newAudio;
      } catch (e) {
        if (e.code === 404) {
          console.error("call history transcript not found");
        } else {
          console.error(e);
        }
      } finally {
        dispatch(
          setLoadingBackdrop({
            key: "CallHistoryDetailFetchAudio",
            state: false,
          }),
        );
      }
    };
    fetch();
  }, [callHistoryId]);

  const callerBox = (response: string, key: number) =>
    response && (
      <Box display="flex" gap={1} ml="auto" key={key} pl={10}>
        <Box
          px={2}
          py={1}
          bgcolor="#999"
          color="#fff"
          fontSize="0.85rem"
          borderRadius={1}
        >
          {(scriptLines && scriptLines[response]?.[0]) || `${response}`}
        </Box>
        <Avatar sx={{ bgcolor: "#999" }}>AI</Avatar>
      </Box>
    );

  const calleeBox = (response: string, key: number) =>
    response && (
      <Box display="flex" gap={1} key={key} pr={10}>
        <Avatar sx={{ bgcolor: "#DCDCDC", color: "#000", fontSize: "1rem" }}>
          顧客
        </Avatar>
        <Box
          px={2}
          py={1}
          bgcolor="#DCDCDC"
          fontSize="0.85rem"
          borderRadius={1}
        >
          {response}
        </Box>
      </Box>
    );

  const handleClickPlay = () => {
    audioRef.current.play();
  };

  const handleClickPause = () => {
    audioRef.current.pause();
  };

  const handleAudioSliderChange = (event, value) => {
    setAudioCurrentTime(value);
    audioRef.current.currentTime = value;
  };

  // ページ遷移時に再生を停止
  useEffect(() => {
    return () => {
      if (audioRef.current) audioRef.current.pause();
    };
  }, [location]);

  const fetchCallConversationCopy = async (): Promise<string> => {
    if (!transcriptArray) {
      throw "コピーに失敗しました";
    }
    let copyText = "";
    await transcriptArray.map((line) => {
      copyText += line.speaker + ":" + line.content + "\n";
    });

    return copyText;
  };

  const handleCopyClick = async () => {
    if (!callHistoryId) return;
    try {
      const copyText = await fetchCallConversationCopy();
      await navigator.clipboard.writeText(copyText);
      dispatch(
        setSnackbar({
          open: true,
          text: "コピーしました",
          severity: "success",
        }),
      );
    } catch (error) {
      console.error("コピーに失敗しました", error);
      dispatch(
        setSnackbar({
          open: true,
          text: "コピーに失敗しました",
          severity: "error",
        }),
      );
    }
  };

  const fetchAudioFile = async (
    callHistoryId: string,
    callInfo: CallHistoryRecord,
  ): Promise<void> => {
    const result = await request({
      path: `/calls/{callId}/recording`,
      httpMethod: "get",
      responseType: "blob",
      params: {
        paths: {
          callId: callHistoryId,
        },
      },
    });

    if (result.error) {
      console.error("fetchAudioFile error", result.error);
      dispatch(
        setSnackbar({
          open: true,
          text: "ダウンロードに失敗しました",
          severity: "error",
        }),
      );
    }

    const blobUrl = URL.createObjectURL(result.data);
    const link = document.createElement("a");
    const companyName = callInfo?.companyName || "unknown";
    const callDate = dayjs(callInfo?.calledAt).format("YYYY-MM-DD");
    const callResult = callInfo?.result
      ? callResultLabels[callInfo?.result]
      : "";
    const fileName = `${companyName}-${callDate}-${callResult}.wav`;
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    dispatch(
      setSnackbar({
        open: true,
        text: "ダウンロードしました",
        severity: "success",
      }),
    );
  };

  const isUnreachable =
    callHistory === null ||
    callHistory === undefined ||
    callHistory?.result === "UNREACHABLE";

  return (
    <Container maxWidth="lg" sx={{ p: 4 }}>
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      <Stack mt={4} sx={{ px: 2 }} minHeight={400}>
        <Grid container spacing={2}>
          {headerItems.map((item) => (
            <Grid item xs={2} key={item.label} minHeight={120}>
              <Stack
                bgcolor="#fff"
                borderRadius={2}
                px={{ md: 1, lg: 2, xl: 3 }}
                overflow="hidden"
                height="100%"
                justifyContent="center"
              >
                <Typography fontSize="0.8rem" color="#888" noWrap>
                  {item.label}
                </Typography>
                {["会社名", "コールリスト", "スクリプト"].includes(
                  item.label,
                ) ? (
                  <Tooltip title={item.content} placement="bottom">
                    <Typography
                      textOverflow="ellipsis"
                      overflow="hidden"
                      noWrap
                    >
                      {item.content}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography textOverflow="ellipsis" overflow="hidden" noWrap>
                    {item.content}
                  </Typography>
                )}
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={3} mt={2} height="100%" minHeight={600}>
          <Grid item xs={3}>
            <Grid container direction="column">
              <Grid item xs={8}>
                <Box bgcolor="#fff" borderRadius={2} overflow="hidden">
                  <Box bgcolor="#ddd" py={0.8} px={2}>
                    <Typography fontSize="0.85rem" textAlign="left">
                      コールメモ
                    </Typography>
                  </Box>
                  <Box p={2} fontSize="0.85rem">
                    <TextField
                      minRows={5}
                      multiline
                      size="small"
                      value={callMemo || callHistory?.callMemo}
                      placeholder="(メモなし)"
                      onChange={(e) => setCallMemo(e.target.value)}
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "0.85rem",
                        },
                      }}
                    ></TextField>
                    <Box mt={1} textAlign="left">
                      <Button
                        variant="contained"
                        disabled={callMemo === (callHistory?.callMemo || "")}
                        onClick={() => onChangeCallResult({ callMemo })}
                      >
                        変更
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} pt={2}>
                <Stack
                  bgcolor="#fff"
                  height="100%"
                  borderRadius={2}
                  overflow="hidden"
                >
                  <Box
                    bgcolor="#ddd"
                    py={0.8}
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontSize="0.85rem" textAlign="left">
                      録音
                    </Typography>
                    <Tooltip title="ダウンロード" placement="top">
                      <IconButton
                        onClick={() =>
                          fetchAudioFile(callHistoryId, callHistory)
                        }
                        disabled={audioDuration === 0 || isUnreachable}
                        size="small"
                      >
                        <Download fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Stack p={2}>
                    <Slider
                      step={1}
                      size="small"
                      sx={{ mb: 2 }}
                      value={audioCurrentTime}
                      onChange={handleAudioSliderChange}
                      max={audioDuration || 0}
                      disabled={isUnreachable}
                    ></Slider>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mt: -2,
                      }}
                    >
                      <TinyText>{formatDuration(audioCurrentTime)}</TinyText>
                      <TinyText>
                        {isUnreachable
                          ? "0:00"
                          : formatDuration(audioDuration - audioCurrentTime)}
                      </TinyText>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={
                          audioRef.current?.paused
                            ? handleClickPlay
                            : handleClickPause
                        }
                        disabled={isUnreachable}
                      >
                        {audioRef.current?.paused ? (
                          <PlayArrow fontSize="large"></PlayArrow>
                        ) : (
                          <Pause fontSize="large"></Pause>
                        )}
                      </IconButton>
                    </Box>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4} pt={2}>
                <Stack
                  bgcolor="#fff"
                  height="100%"
                  borderRadius={2}
                  overflow="hidden"
                >
                  <Box
                    bgcolor="#ddd"
                    py={0.8}
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontSize="0.85rem" textAlign="left">
                      推奨コール時間タグ
                    </Typography>
                  </Box>
                  <Stack p={2}>
                    <Box display="flex" gap={1}>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {callHistory?.callTags?.map((tag, index) => (
                          <Chip
                            key={index}
                            label={tag}
                            style={{
                              backgroundColor: "#ddd",
                              padding: "0.5rem",
                              borderRadius: "1rem",
                              margin: "0.25rem",
                            }}
                          />
                        ))}
                      </div>
                    </Box>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Box
              bgcolor="#fff"
              height="100%"
              borderRadius={2}
              overflow="hidden"
            >
              <Box
                bgcolor="#ddd"
                py={0.8}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontSize="0.85rem" textAlign="left">
                  文字起こし
                </Typography>
                <Tooltip title="コピー" placement="top">
                  <IconButton
                    onClick={handleCopyClick}
                    disabled={transcriptArray.length === 0}
                    size="small"
                  >
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ overflowY: "scroll", scrollbarColor: "#ddd #f6f6f6" }}>
                <Stack p={3} gap={1} maxWidth={680} maxHeight={550} mx="auto">
                  {!isUnreachable &&
                    transcriptArray.map((line, k) =>
                      line.speaker === "FROM"
                        ? callerBox(line.content, k)
                        : line.speaker === "TO"
                          ? calleeBox(line.content, k)
                          : null,
                    )}
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

export default HistoryDetail;
