import { CallResult } from "@/models/CallHistory";
import {
  RedialContact,
  AiNurturingContactList,
  AiNurturingList,
} from "@/models/Call";
import { request } from "@/models/telai-backend/client";
import { paths } from "@/models/telai-backend/schema";

type OderBy =
  paths["/contacts/query"]["post"]["requestBody"]["content"]["application/json"]["orderBy"];
// orderByのバリデーション
const validOrderBy: OderBy[] = [
  "sequence",
  "name",
  "phoneNumber",
  "callNoteResult",
  "createdAt",
  "callCount",
  "createdAt",
  "latestCallCalledAt",
  "latestCallNextCallScheduledAt",
  "latestCallNoteResult",
];

const checkOrderBy = (orderBy: string): OderBy => {
  const castedOrderBy = orderBy as OderBy;
  return validOrderBy.includes(castedOrderBy) ? castedOrderBy : "createdAt";
};

const getAINurturingContactList = async ({
  callResultsToRedial,
  redialMaxAttempts,
  redialIntervalDays,
  listId,
  latestCallScriptId,
  limit,
  offset,
  orderBy,
  order,
}: {
  callResultsToRedial: CallResult[];
  redialMaxAttempts: { [key in CallResult]?: number };
  redialIntervalDays?: { [key in CallResult]?: number };
  listId: string;
  latestCallScriptId: string;
  limit?: number;
  offset?: number;
  orderBy?: string;
  order?: "asc" | "desc";
}): Promise<AiNurturingContactList> => {
  console.log(
    "getAINurturingContactList",
    callResultsToRedial,
    redialMaxAttempts,
    redialIntervalDays,
    orderBy,
    order,
  );

  const res = await request({
    path: "/contacts/query",
    httpMethod: "post",
    params: {
      body: {
        listId: listId,
        limit: limit ?? 10,
        offset: offset ?? 0,
        latestCallScriptId: latestCallScriptId,
        orderBy: checkOrderBy(orderBy ?? "createdAt"),
        order: order ?? "asc",
        callNoteResults: callResultsToRedial,
        calledAtStart: undefined,
        calledAtEnd: undefined,
        query: undefined,
        hasCallTag: undefined,
        latestCallNoteNextCallCompleted: false,
      },
    },
  });

  if (res.result === "error") {
    throw new Error(res.error.data.message);
  }

  console.log("aiNurturingContactList", res.data.contacts);

  return {
    total: res.data.total,
    contacts: res.data.contacts,
  };
};

const getRedialCalls = async ({
  callResultsToRedial,
  hasCallTag,
  limit,
  offset,
  orderBy,
  order,
}: {
  callResultsToRedial: CallResult[];
  hasCallTag?: boolean;
  limit?: number;
  offset?: number;
  orderBy?: string;
  order?: "asc" | "desc";
}): Promise<RedialContact> => {
  console.log("getRedialCalls", callResultsToRedial);

  const res = await request({
    path: "/contacts/query",
    httpMethod: "post",
    params: {
      body: {
        listId: undefined,
        limit: limit ?? 10,
        offset: offset ?? 0,
        orderBy: checkOrderBy(orderBy ?? "createdAt"),
        order: order ?? "asc",
        callNoteResults: callResultsToRedial,
        latestCallNoteNextCallCompleted: false,
        calledAtStart: undefined,
        calledAtEnd: undefined,
        hasCallTag: hasCallTag ?? undefined,
        query: undefined,
      },
    },
  });

  // 何回再コールをしたか表示するため、再コール回数を-1する
  const redialContacts = res.data.contacts.map((contact) => {
    const redialContact = { ...contact };
    redialContact.callCount = redialContact.callCount - 1;
    return redialContact;
  });

  if (res.result === "error") {
    throw new Error(res.error.data.message);
  }
  console.log(
    "getRedialCalls",
    redialContacts,
    "res.data.contacts",
    res.data.contacts,
  );

  return {
    total: res.data.total,
    contacts: redialContacts,
  };
};

const getAINurturingList = async ({
  callResultsToRedial,
  limit = 100,
  offset = 0,
}: {
  callResultsToRedial: CallResult[];
  limit?: number;
  offset?: number;
}): Promise<AiNurturingList> => {
  const res = await request({
    path: "/contacts/group/list_script/query",
    httpMethod: "post",
    params: {
      body: {
        limit: limit,
        offset: offset,
        orderBy: "createdAt",
        order: "asc",
        callNoteResults: callResultsToRedial,
        calledAtStart: undefined,
        calledAtEnd: undefined,
        query: undefined,
      },
    },
  });

  return res.data.contactLists;
};

export { getRedialCalls, getAINurturingContactList, getAINurturingList };
